
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicForm from "@/views/resources/documentation/element-ui/form/form/BasicForm.vue";
import EUIInlineForm from "@/views/resources/documentation/element-ui/form/form/InlineForm.vue";
import EUIAlignment from "@/views/resources/documentation/element-ui/form/form/Alignment.vue";
import EUIValidation from "@/views/resources/documentation/element-ui/form/form/Validation.vue";
import EUICustomValidationRules from "@/views/resources/documentation/element-ui/form/form/CustomValidationRules.vue";
import EUIDeleteOrAddFormItemsDynamically from "@/views/resources/documentation/element-ui/form/form/DeleteOrAddFormItemsDynamically.vue";
import EUINumberValidate from "@/views/resources/documentation/element-ui/form/form/NumberValidate.vue";
import EUISizeControl from "@/views/resources/documentation/element-ui/form/form/SizeControl.vue";

export default defineComponent({
  name: "form",
  components: {
    EUIBasicForm,
    EUIInlineForm,
    EUIAlignment,
    EUIValidation,
    EUICustomValidationRules,
    EUIDeleteOrAddFormItemsDynamically,
    EUINumberValidate,
    EUISizeControl
  },
  setup() {
    setCurrentPageTitle("Form");
  }
});
